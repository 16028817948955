/* * { */
/*   box-sizing: border-box; */
/*   margin: 0; */
/*   padding: 0; */
/* } */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
body {
  font-family: Inter, sans-serif;
  /* background-color: #282a36; */
  /* color: #f8f8f2; */
}

.box {
  padding: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-bottom: 20px;
}

.container {
  opacity: 1;
  padding: 20px;
  padding-top: 70px;
  margin: auto;
  max-width: 600px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
}

.title {
  align-items: center;
  background-color: var(--color-bg-alt);
  padding: 40px;
}

.title a {
  border: 1px solid var(--color-blossom-dark);
  padding: 5px 15px;
  border-radius: 25px;
}

.content {
  background-color: var(--color-bg-alt);
  height: auto;
  grid-area: main;
  min-height: 0;
  padding: 30px;
}

.content h3 {
  font-size: 1.6em;
}


.content p {

}


.storyTitle {
  padding: 20px;
  min-width: 100px;
  max-width: 100px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;

}
.section {
  margin-bottom: 30px;
}


h1 {
  font-size: 2rem;
}

.profile-pic {
  max-width: 150px;
  height: auto;
  border-radius: 50%;
  display: block;
  margin: auto;
  margin-bottom: 1rem;
}

.storyContainer {
  display: flex;
  flex-direction: row;
  margin: 25px 0px;
}

.title h1 {
  font-style: italic;
  font-weight: 400;
  font-family: "Space Mono", serif;
  font-size: 2.8em;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 15px;
}

.footer {
  margin-top: 20px;
  text-align: center;
  font-size: 1.1rem;
  color: #6272a4;

}

.recaptcha {
  margin-top: 10px;
}

.grecaptcha-badge {
    visibility: hidden;
}

.emailForm {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 420px) {
  .title h1 {
    font-size: 2.8rem;
  }
  div.storyContainer {
    flex-direction: column;
    margin: 15px 0px;
  }

  div.storyTitle {
    max-width: 100%;
    min-width: 10vw;
  }
}

label {
}

#nsfwCheckbox {
  margin: 5px;
}

